class AdAccordion extends HTMLElement {
	connectedCallback() {
		const children = this.querySelector(
			".accordion > .accordion__cards",
		)?.children;
		if (!children) {
			return;
		}
		this.cards = [...children];
		this.unique = this.dataset.unique !== "false";

		for (const card of this.cards) {
			const title = card.querySelector(".accordion-card__title");
			title.addEventListener("click", (e) => {
				e.preventDefault();
				this.expandAccordion(card);
			});
		}
	}

	expandAccordion(card) {
		const isExpanded =
			card.querySelector(".accordion-card__title").ariaExpanded === "true";

		for (const iCard of this.cards) {
			const isICardExpanded =
				iCard.querySelector(".accordion-card__title").ariaExpanded === "true";

			this.toggleCard(
				iCard,
				iCard !== card ? (this.unique ? false : isICardExpanded) : !isExpanded,
			);
		}

		const title = this.getTitle();
		window.dataLayerPush({
			event: "e_expandList",
			action: !isExpanded ? "open" : "close",
			listName: title, // Set to title of block
			listItem: card.querySelector(".accordion-card__title").innerText, // Set to text of accordion item
		});
	}

	getTitle() {
		const headline = this.querySelector(".accordion__headline");
		if (headline) return headline;

		const parentTabId =
			this.parentElement?.getAttribute("aria-labelledby") ??
			this.parentElement?.dataset.accordionBtn;

		const tab = document.querySelector(`#${parentTabId}`);
		if (!tab) return "";
		return tab.innerText;
	}

	toggleCard(card, expanded) {
		const title = card.querySelector(".accordion-card__title");
		const icon = card.querySelector(".fa");
		const content = card.querySelector(".accordion-card__panel");

		title.ariaExpanded = expanded;
		content.ariaHidden = !expanded;
		if (expanded) return icon.classList.replace("fa-plus", "fa-minus");
		return icon.classList.replace("fa-minus", "fa-plus");
	}
}

export default AdAccordion;
