const getCookies = () => document.cookie?.split("; ") ?? [];
/**
 * Get the value of a cookie by its key.
 *
 * @param {string} key - The name of the cookie to retrieve.
 * @returns {string | undefined} - The value of the cookie, or undefined if not found.
 */
export const getCookie = (key) => {
	const cookies = getCookies();
	for (const cookie of cookies) {
		const [cookieKey, cookieValue] = cookie.split("=");
		if (cookieKey === key) {
			return decodeURIComponent(cookieValue);
		}
	}
	return undefined;
};

export const getSessionID = () => {
	const sessionID = getCookie("session_id");
	if (sessionID && sessionID.length > 0) return sessionID;
	const gaCookie = getCookies().find(
		(cookie) => cookie.startsWith("_ga_") && !cookie.includes("_ga_111111111"),
	);
	if (!gaCookie) return "";
	const splittage = gaCookie.split(".");
	if (splittage.length > 3) {
		return splittage[2];
	}
	return "";
};
